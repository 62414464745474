const config = {
  rootProjectId: "root",
  uiBucketName: "a101prod.c.retter.io",
  appUrl: "https://api.a101prod.retter.io/",
  cosUrl: "api.a101prod.retter.io",
  version: "2.1.0",
  captchaKey: "6Ld0a6QpAAAAAF-CG-j5BO1UwxVIhPHOvsPuqbxa",
  stage: "PROD"
}
export default config

